import React, { useState, useEffect } from "react";
import { meal } from "../../constants";
import "./Intro.css";

const Intro = () => {
  const [userInteracted, setUserInteracted] = useState(false);
  const vidRef = React.useRef();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.1, // The percentage of the target element which needs to be visible for the callback to be invoked
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // If the video element is intersecting with the viewport, start playing the video
          setUserInteracted(true);
          vidRef.current.play();
        }
      });
    }, options);

    observer.observe(vidRef.current);

    // Cleanup function to disconnect the observer when component unmounts
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div className="app__video">
      <video
        ref={vidRef}
        src={meal}
        type="video/mp4"
        loop
        controls={false}
        muted
        autoPlay
      />
    </div>
  );
};

export default Intro;
