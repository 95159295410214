import halalLogo from "../assets/halalLogo.webp";
import Kb from "../assets/Kb.webp";
import doordash from "../assets/doordash.webp";
import ubereats1 from "../assets/ubereats.webp";
import spoon from "../assets/spoon.svg";
import FinalLogo from "../assets/FinalLogo1.webp";
import pcsChickenWingsOnly8 from "../assets/8pcsChickenWingsOnly.webp";
import pcsChickenWingsSaladNaan8 from "../assets/8pcsChickenWingsSaladNaan.webp";
import pcsChickenWingsOnly12 from "../assets/12pcsChickenWingsOnly.webp";
import pcsChickenWingsOnlySaladNaan12 from "../assets/12pcsChickenWingsOnlySaladNaan.webp";
import BeefFor2 from "../assets/BeefFor2.webp";
import BeefTikkaDinner from "../assets/BeefTikkaDinner.webp";
import BeefTikkaSaladNaan from "../assets/BeefTikkaSaladNaan.webp";
import BigPicture from "../assets/BigPicture.jpg";
import BigPicture1 from "../assets/BigPicture1.webp";
import ChapleeKabobDinner from "../assets/ChapleeKabobDinner.webp";
import ChapleeKabobSaladNaan from "../assets/ChapleeKabobSaladNaan.webp";
import ChickenChapleeKabobDinner from "../assets/ChickenChapleeKabobDinner.webp";
import ChickenChapleeKabobSaladNaan from "../assets/ChickenChapleeKabobSaladNaan.webp";
import ChickenFor2 from "../assets/ChickenFor2.webp";
import ChickenKoftaKabobDinner from "../assets/ChickenKoftaKabobDinner.webp";
import ChickenWhiteBreastKabobDinner from "../assets/ChickenWhiteBreastKabobDinner.webp";
import ChickenWhiteBreastKabobSaladNaan from "../assets/ChickenWhiteBreastKabobSaladNaan.webp";
import FamilyPlatter1 from "../assets/FamilyPlatter1.webp";
import FamilyPlatter2 from "../assets/FamilyPlatter2.webp";
import HalfChickenDinner from "../assets/HalfChickenDinner.webp";
import KoftaKabobSaladNaan from "../assets/KoftaKabobSaladNaan.webp";
import QabuleeRiceWithRasinCarrot from "../assets/QabuleeRiceWithRasinCarrot.webp";
import SultaniKabobSaladNaan from "../assets/SultaniKabobSaladNaan.webp";
import TandooriBreastKabobDinner from "../assets/TandooriBreastKabobDinner.webp";
import TandooriBreastKabobSaladNaan from "../assets/TandooriBreastKabobSaladNaan.webp";
import WazeriKabobDinner from "../assets/WazeriKabobDinner.webp";
import WazeriKabobSaladNaan from "../assets/WazeriKabobSaladNaan.webp";
import seekh from "../assets/seekh.webp";
export default {
  Kb,
  halalLogo,
  spoon,
  seekh,
  FinalLogo,
  pcsChickenWingsSaladNaan8,  
  pcsChickenWingsOnly8,
  pcsChickenWingsOnly12,
  pcsChickenWingsOnlySaladNaan12,
  BeefFor2,
  BeefTikkaDinner,
  BeefTikkaSaladNaan,
  BigPicture,
  BigPicture1,
  ChapleeKabobDinner,
  ChapleeKabobSaladNaan,
  ChickenChapleeKabobDinner,
  ChickenChapleeKabobSaladNaan,
  ChickenFor2,
  ChickenKoftaKabobDinner,
  ChickenWhiteBreastKabobDinner,
  ChickenWhiteBreastKabobSaladNaan,
  FamilyPlatter1,
  FamilyPlatter2,
  HalfChickenDinner,
  KoftaKabobSaladNaan,
  QabuleeRiceWithRasinCarrot,
  SultaniKabobSaladNaan,
  TandooriBreastKabobDinner,
  TandooriBreastKabobSaladNaan,
  WazeriKabobDinner,
  WazeriKabobSaladNaan,
  doordash,
  ubereats1, 
};
